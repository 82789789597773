<template>
  <div class="bottom_fix">
    <a class="my_btn" href="#apply_form"
      >応募する<i class="fas fa-chevron-right"></i
    ></a>
  </div>
</template>
<script>
export default {}
</script>

<template>
  <section>
    <div class="container">
      <div class="row banner_area text-center">
        <div class="col-lg-6 mb-5">
          <router-link :to="{ name: 'article', params: { id: 48848 } }">
            <img
              src="../assets/hirunomi_banner.jpg"
              alt="日本酒専門店・日本酒バーのKURANDSAKEMARKETのサービス①"
            />
          </router-link>
        </div>
        <div class="col-lg-6 mb-5">
          <p class="text-center mb-0">
            土・日・祝日お昼限定！お得に日本酒を楽しむ「昼飲みキャンペーン」を各店舗にて実施しています。<br />
            日本酒飲み比べし放題プラン（時間無制限）の料金を2,000円（税別）でお楽しみいただけるキャンペーンです。
          </p>
        </div>
        <div class="col-lg-6 mb-5">
          <router-link to="/group-discount">
            <img
              src="../assets/group_banner.jpg"
              alt="日本酒専門店・日本酒バーのKURANDSAKEMARKETのサービス②"
            />
          </router-link>
        </div>
        <div class="col-lg-6">
          <router-link to="/reserved">
            <img
              src="../assets/reserved.jpg"
              alt="日本酒専門店・日本酒バーのKURANDSAKEMARKETのサービス③"
            />
          </router-link>
        </div>
        <div class="col-lg-6">
          <router-link :to="{ name: 'article', params: { id: 56298 } }">
            <img
              src="../assets/passport_banner.jpg"
              alt="日本酒専門店・日本酒バーのKURANDSAKEMARKETのサービス④"
            />
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {}
</script>

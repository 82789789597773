<template>
  <div class="top_catch">
    <div class="main_slide">
      <slick :options="slickOptions">
        <img
          class="main_slide_img"
          src="../assets/top_catch.jpg"
          :alt="
            `日本酒専門店・日本酒バーのKURANDSAKEMARKET${storeName}のメインビジュアル`
          "
        />
        <img
          class="main_slide_img"
          src="../assets/top_catch02.jpg"
          :alt="
            `日本酒専門店・日本酒バーのKURANDSAKEMARKET${storeName}のメインビジュアル`
          "
        />
        <img
          class="main_slide_img"
          src="../assets/top_catch03.jpg"
          :alt="
            `日本酒専門店・日本酒バーのKURANDSAKEMARKET${storeName}のメインビジュアル`
          "
        />
        <img
          class="main_slide_img"
          src="../assets/top_catch04.jpg"
          :alt="
            `日本酒専門店・日本酒バーのKURANDSAKEMARKET${storeName}のメインビジュアル`
          "
        />
      </slick>
      <div class="key_text">
        <img src="../assets/key_text.svg" alt="" />
        <span v-if="storeName && $route.name !== 'home'">{{ storeName }}</span>
      </div>
    </div>

    <event></event>
  </div>
</template>

<script>
import Slick from 'vue-slick'
import Event from './Event'

export default {
  data() {
    return {
      slickOptions: {
        arrows: false,
        dots: this.$isSp ? false : true,
        fade: true,
        autoplay: true,
        autoplaySpeed: 1500,
        pauseOnHover: false,
      },
    }
  },
  components: {
    Slick,
    Event,
  },
  mounted() {
    $('.main_slide_img').height(
      this.$isSp ? $(window).height() / 2 : $(window).height(),
    )
  },
  computed: {
    storeName() {
      return this.$store.state.storeObj
        ? this.$store.state.storeObj.title.rendered
        : null
    },
  },
}
</script>

<style lang="scss">
.slick-dots {
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 0;
  padding: 0;
  li {
    list-style: none;
    margin: 0 10px;
    display: inline-block;
    button {
      border: none;
      text-indent: -9999px;
      border-radius: 50%;
      background-color: rgba(#000, 0.5);
      width: 15px;
      height: 15px;
    }
  }
}
</style>

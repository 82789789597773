<template>
  <section class="store">
    <img
      class="store_img"
      src="../assets/store.jpg"
      alt="日本酒専門店・日本酒バーのKURANDSAKEMARKETの乾杯イメージ"
    />
    <div class="container">
      <h2>
        <span>STORE</span>
        <img width="340" src="../assets/key_text.svg" alt="" />
      </h2>

      <div class="store_list row">
        <div class="col-lg-6" v-for="(store, i) in storeList" :key="i">
          <div class="store_cont">
            <router-link :to="'/' + store.slug">
              <h3>{{ store.title.rendered }}</h3>
              <p v-html="store.acf.address"></p>
              <p>予約専用電話番号 {{ store.acf.reserve_tel }}</p>
              <i class="fas fa-chevron-right store_link"></i
            ></router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      stores: [],
    }
  },
  mounted() {
    // sakemarket
    //   .getList()
    //   .then(response => {
    //     this.stores = response.data
    //   })
    //   .catch(error => {
    //     // handle error
    //     console.log(error)
    //   })
  },
  computed: {
    ...mapState(['storeLoading', 'storeList', 'modalShow', 'drawerOpened']),
  },
}
</script>

<template>
  <div class="recruit_wrap">
    <div class="recruit_catch">
      <img
        width="100%"
        v-if="!$isSp"
        src="../assets/recruit_catch.jpg"
        alt=""
      />
      <img
        width="100%"
        v-if="$isSp"
        src="../assets/recruit_catch_sp.png"
        alt=""
      />
      <img
        :width="$isSp ? 195 : 385"
        class="logo"
        src="../assets/key_text.svg"
        alt=""
      />
      <div class="recruit_catch_text">
        <img width="776" src="../assets/recruit_copy.png" alt="" />
      </div>
    </div>
    <section class="about_area">
      <div class="container">
        <h2 class="about_kurand_h">
          KURAND<br />
          SAKE MARKETとは
        </h2>
        <h3 class="head">
          私たちの紹介
        </h3>
        <p
          class="text"
          v-html="
            nl2br(`「KURAND SAKE MARKET（クランドサケマーケット）」は、日本酒を中心とした100種類のお酒を、自分の好きな飲み方で飲み比べることができるテイスティングバーです。料理の持ち込みや出前もできます。普段は飲むことのできない、小さな酒蔵の希少な日本酒だけを取り揃えています。

お酒の飲み比べを存分に楽しむための自由な空間で、日本酒と皆さまとの新しい出会いを提供する飲み比べ専門店、それが「KURAND SAKE MARKET」です。`)
          "
        ></p>
      </div>
    </section>
    <div class="boshu_catch_area">
      <img v-if="!$isSp" src="../assets/boshu_catch.png" alt="" />
      <img v-if="$isSp" src="../assets/boshu_catch_sp.jpg" alt="" />
      <div class="container">
        <img
          :width="$isSp ? 211 : 422"
          class="catch"
          src="../assets/recruit_copy2.png"
        />
        <img
          :width="$isSp ? 190 : 424"
          class="circle"
          src="../assets/recruit_miryoku_c_img.png"
        />
      </div>
    </div>
    <section class="boshu_section">
      <div class="container">
        <div class="block">
          <h3 class="head">
            仕事内容
          </h3>
          <p class="text mb-5">
            <strong class="em_text">
              明るい笑顔で<br />
              お客さまに日本酒をオススメすること<br />
            </strong>
            当店はセルフスタイルですが、お客さまとのコミュニケーションを非常に大切にしています。「私にぴったりな日本酒を教えてほしい！」、「◯◯な飲み口の日本酒を飲みたい！」というお客さまに、ベストな日本酒をご提案してください。また、お客さまにまた来たい！と思ってもらえるような、過ごしやすい環境を作っていただきます。
          </p>
          <h3 class="head">
            求める人材
          </h3>
          <p class="text mb-5">
            <strong class="sub_em_text"
              >接客が好き！日本酒が好き！お客様の喜んだ顔が好き！必要なのはそれだけ！<br />
              こんな気持ちがあれば専門スキルはいりません！</strong
            ><br />
            店長や先輩スタッフが丁寧に指導いたしますので、飲食未経験の方でもご安心ください！まずは研修で、基本的な日本酒の知識やマナーなどを学んでいただきますので、現時点で日本酒に詳しくなくても大丈夫です。働きながら詳しくなっていきましょう！
          </p>
        </div>

        <h2 class="text-center mb-4">募集情報</h2>

        <div class="row apply_info_wrap">
          <div class="col-lg-6 col-auto" v-for="(store, i) in stores" :key="i">
            <div class="apply_info_cont">
              <h3>{{ store.title.rendered }}</h3>
              <table>
                <tr>
                  <th>業務内容</th>
                  <td>
                    日本酒専門店のホールスタッフ
                  </td>
                </tr>
                <tr>
                  <th>雇用形態</th>
                  <td>
                    アルバイト（期間の定めなし）
                  </td>
                </tr>
                <tr>
                  <th>勤務地</th>
                  <td v-html="store.acf.address"></td>
                </tr>
                <tr>
                  <th>アクセス</th>
                  <td v-html="store.acf.station"></td>
                </tr>
                <tr>
                  <th>時給</th>
                  <td>
                    1,020円〜1,620円<br />
                    ※深夜、法定時間外は25％アップ<br />
                    ※毎月時給UPのチャンス！
                  </td>
                </tr>
                <tr>
                  <th>
                    昇給制度
                  </th>
                  <td>
                    アドバイザー：1,040円<br />
                    店長代行：1,120円<br />
                    エキスパート：1,270円<br />
                    コンセイエ：1,620円
                  </td>
                </tr>
                <tr>
                  <th>勤務時間</th>
                  <td>
                    11時～23時（シフト申告制）<br />
                    ※平日は17時〜、土日祝は11時〜となります。
                  </td>
                </tr>
              </table>

              <a href="#apply_form" class="my_btn">
                応募はこちら
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="miryoku_area">
      <div class="container">
        <h2 class="main_h">
          アルバイトの魅力
        </h2>
        <div class="miryoku_cont_list">
          <div
            :class="`miryoku_cont cont${i + 1}`"
            v-for="(miryoku, i) in miryoku_items"
            :key="i"
          >
            <div class="detail">
              <p>{{ miryoku.text }}</p>
              <a href="">
                詳しくはこちら<img
                  width="9"
                  src="../assets/rec_arrow.svg"
                  alt=""
                />
              </a>
            </div>
            <div class="img">
              <span class="catch" v-html="nl2br(miryoku.catch)"></span>
              <img
                class="circle"
                :src="require(`../assets/miryoku_c${i + 1}.png`)"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="system_area">
      <div class="container">
        <h2 class="main_h">
          評価・昇給制度
        </h2>
        <div class="block">
          <h3 class="head">昇給制度</h3>
          <div class="text mb-5">
            定期的に開催される、お酒にまつわるテストを受けていただくことで昇給可能です。

            <table class="recruit_table" v-if="!$isSp">
              <thead>
                <tr>
                  <td></td>
                  <th v-for="(name, i) in Object.keys(positions)" :key="i">
                    {{ name }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(name, i) in Object.keys(positions['アドバイザー'])"
                  :key="i"
                >
                  <th>{{ name }}</th>
                  <td v-for="(position, key) in positions" :key="key">
                    {{ position[name] }}
                  </td>
                </tr>
              </tbody>
            </table>

            <div v-if="$isSp">
              <table
                class="recruit_table"
                v-for="(position, key) in positions"
                :key="key"
              >
                <thead>
                  <tr>
                    <th colspan="2">
                      {{ key }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(vakue, header) in position" :key="header">
                    <th>{{ header }}</th>
                    <td>
                      {{ vakue }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            その他、お客さまの声やお店への貢献度も評価対象になります。高評価の方には、お酒のプレゼントや昇給などの特典も！
          </div>

          <h3 class="head">キャリアパスについて</h3>
          <div class="text">
            アルバイトの方にも、正社員になれるチャンスがあります。
            <h4 class="sub_h mt-4">
              キャリアパス例
            </h4>
            <div class="career_list_area">
              <div class="career_cont" v-for="(career, i) in careers" :key="i">
                <img :src="require(`../assets/career${i + 1}.png`)" alt="" />
                <span class="career_step">{{ career }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="interview_area">
      <div class="container">
        <h2 class="main_h">
          インタビュー
        </h2>
        <div class="interview_list">
          <div
            class="interview_cont"
            v-for="(interview, i) in interviews"
            :key="i"
          >
            <div class="img">
              <img :src="require('../assets/' + interview.img)" alt="" />
            </div>
            <div class="detail">
              <h3>{{ interview.name }}</h3>
              <div class="cont" v-html="interview.text"></div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="recruit_slider">
      <h2>アルバイトの様子</h2>
      <div class="slide_wrap">
        <slick :options="slickOptions">
          <div class="slide" v-for="n in 5" :key="n">
            <img :src="require(`../assets/slide${n}.jpg`)" alt="" />
          </div>
        </slick>
      </div>
    </section>

    <section id="apply_form" class="apply_area">
      <div class="container">
        <div class="text-center">
          <a
            href="https://forms.gle/aWLEJzgEV73DnBaN7"
            class="btn btn-danger btn-lg"
          >
            応募フォームはこちら
          </a>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import Slick from 'vue-slick'
export default {
  metaInfo() {
    return {
      title: 'KURAND SAKE MARKETアルバイト募集',
      meta: [
        {
          name: 'description',
          content:
            '接客が好き！日本酒が好き！お客様の喜んだ顔が好き！必要なのはそれだけ！こんな気持ちがあれば専門スキルはいりません！店長や先輩スタッフが丁寧に指導いたしますので、飲食未経験の方でもご安心ください！まずは研修で、基本的な日本酒の知識やマナーなどを学んでいただきますので、現時点で日本酒に詳しくなくても大丈夫です。働きながら詳しくなっていきましょう！',
        },
        {
          property: 'og:title',
          content: 'KURAND SAKE MARKETアルバイト募集',
        },
        {
          property: 'og:description',
          content:
            '接客が好き！日本酒が好き！お客様の喜んだ顔が好き！必要なのはそれだけ！こんな気持ちがあれば専門スキルはいりません！店長や先輩スタッフが丁寧に指導いたしますので、飲食未経験の方でもご安心ください！まずは研修で、基本的な日本酒の知識やマナーなどを学んでいただきますので、現時点で日本酒に詳しくなくても大丈夫です。働きながら詳しくなっていきましょう！',
        },
        {
          property: 'og:image',
          content:
            'https://kurand.jp/wp-content/uploads/2019/09/recruit_catch.jpg',
        },
      ],
    }
  },
  components: {
    Slick,
  },
  data() {
    return {
      formUrl: process.env.VUE_APP_API_URL_BASE + '/form',
      miryoku_items: [
        {
          text:
            '全国の蔵元さんや元蔵人スタッフによるKURAND大学の受講が無料です。勤務中も日本酒に詳しい店長や先輩スタッフからお酒のことを学べる上に、全国の弊社店舗利用が半額になるので、プライベートでも試飲しながら勉強ができちゃいます！',
          catch: '日本酒に詳しくなれる！',
        },
        {
          text:
            'パートナー酒蔵さんにご協力いただき、酒蔵見学や酒造り体験も不定期で開催しています。こんなことが気軽にできるのもKURANDならでは！',
          catch: '蔵元さんとの距離が近い！',
        },
        {
          text:
            '毎月日本酒知識テストを受けられて、そのテストに合格すれば時給がUPします！また、接客やお店への貢献度による評価によってもお酒などのプレゼントや時給UPが可能です！',
          catch: `毎月お酒のプレゼントや
            時給UPの可能性があります！`,
        },
      ],
      careers: ['アドバイザー', '店長代行', '社員登用面談', '正社員へ'],
      positions: {
        アドバイザー: {
          出題内容:
            '日本酒または果実酒（リキュール）に関する基礎知識が出題されます。',
          レベル感: '日本酒検定3級相当',
          受験資格: '-',
          手当: '＋20円、専用バッジ、店長代行の権利',
          実施頻度: '月1回',
        },
        エキスパート: {
          出題内容: '日本酒の応用知識が出題されます。',
          レベル感: '唎酒師試験よりもやや難しいです。',
          受験資格: 'アドバイザーであること',
          手当: '＋130円、専用バッジ、社員の権利',
          実施頻度: '月1回',
        },
        コンセイエ: {
          出題内容: 'エキスパートよりも知識の幅と深さが求められます。',
          レベル感: '国際資格SAKE DIPROMAと同等レベルです。',
          受験資格: 'エキスパートであること',
          手当: '＋350円、専用バッジ',
          実施頻度: '3ヶ月に1回',
        },
      },
      interviews: [
        {
          img: 'hosoi.png',
          name: 'HOSOI・大学生（勤続年数：半年）',
          text: `<strong>好きなKURANDのお酒は？</strong>
          <p>群馬</p>
          <strong>応募したきっかけは？</strong>
          <p>応募する少し前に日本酒にどハマリしていて、日本酒にもっと詳しくなりたいと思っていた時ネットでお店の存在を知りました。
そのときにアルバイトの採用を募集していたので勢いで応募したのがきっかけです。</p>
<strong>楽しいと感じるポイントは？</strong>
          <p>以前も飲食店でアルバイトをしていましたが、KURANDはセルフスタイルでお客さまとの距離が近いため、お客さまの反応がすぐに分かります。
結構厳しい方もいますが、その中でもお客さまからお褒めの言葉を貰った時の嬉しさはとてつもなく大きいです！</p>
<strong>これから入る方々へメッセージ</strong>
          <p>「3度の飯よりお酒が好きだ！」
そう思う人には最高の職場だと思います。
そして、入る前よりお酒の事が好きになりますよ！</p>
          `,
        },
        {
          img: 'sango.png',
          name: 'サンゴ・大学生（勤続年数：10ヶ月）',
          text: `<strong>好きなKURANDのお酒は？</strong>
          <p>梧桐、鈴木</p>
          <strong>応募したきっかけは？</strong>
          <p>日本酒がもともと好きだったので、日本酒に関われるアルバイトがしたいとずっと思ってました。
その時、KURANDの存在を思い出してサイトを見たら募集してたので勢いで応募しました。</p>
<strong>楽しいと感じるポイントは？</strong>
          <p>お客さまと話が盛り上がっていろんなことを教えてもらえるとき。
海外からのお客さまが「日本酒美味しい〜！」ってなってるとき。
他のパートナーとお酒の話をする時間が楽しいです。</p>
<strong>これから入る方々へメッセージ</strong>
          <p>みんな一緒にいて面白い人たちなので、働いたら絶対楽しいと思いますよ！</p>
          `,
        },
        {
          img: 'kenchan.png',
          name: 'けんちゃん・フリーター（勤続年数：3年9ヶ月）',
          text: `<strong>好きなKURANDのお酒は？</strong>
          <p>梧桐</p>
          <strong>応募したきっかけは？</strong>
          <p>「未だ日の目を見ない全国の小さな酒蔵を応援する」というコンセプトに惹かれ、自分も一員となって応援したい！と思ったからです。</p>
<strong>楽しいと感じるポイントは？</strong>
          <p>お客さまの求めていることに対して、自分が提案したお酒を飲まれたお客様が笑顔になるときに楽しさを感じます。</p>
<strong>これから入る方々へメッセージ</strong>
          <p>KURANDでは、老若男女や国内外の方を問わず、幅広いお客様に日本のお酒の魅力を伝えることが出来ます。また、KURAND大学という研修制度で、お酒の専門性を身につけることも可能です。是非一緒に、楽しく働きながら、日本のお酒を盛り上げていきましょう！</p>
          `,
        },
      ],
      slickOptions: {
        centerMode: true,
        variableWidth: true,
        prevArrow: `<img class='prev' src='${require('../assets/rec_prev.png')}' />`,
        nextArrow: `<img class='next' src='${require('../assets/rec_next.png')}' />`,
      },
    }
  },
  mounted() {
    window.addEventListener(
      'message',
      function(e) {
        if (e.origin === 'https://api.kurand.jp') {
          $('#kurand_form').height(e.data + 50)
        }
      },
      false,
    )
  },
  computed: {
    stores() {
      console.log(this.$store.state.storeList)
      return this.$store.state.storeList
    },
  },
}
</script>
<style lang="scss" scoped>
$rec_c: #fff000;

.recruit_wrap {
  overflow: hidden;
}

.recruit_catch {
  position: relative;
  .logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .recruit_catch_text {
    position: absolute;
    width: 100%;
    text-align: center;
    color: #fff;
    bottom: -90px;
    padding: 0 15px;
    @include sp {
      bottom: -40px;
    }
  }
}

.about_area {
  padding: 100px 0;
  background-color: $rec_c;
  @include sp {
    padding: 70px 0;
  }
  .about_kurand_h {
    font-size: 84px;
    line-height: 1;
    margin-bottom: 90px;
    color: rgba($color: #000000, $alpha: 0.5);
    @include sp {
      font-size: 42px;
      margin-left: -15px;
      margin-bottom: 10px;
    }
  }
}

.block {
  padding: 0 30px;
  @include sp {
    padding: 0;
  }
}
.main_h {
  font-size: 84px;
  margin-bottom: 80px;
  color: rgba($color: #000000, $alpha: 0.4);
  line-height: 1.1;
  @include sp {
    font-size: 42px;
    margin-left: -15px;
  }
}
.head {
  font-size: 38px;
  position: relative;
  padding-left: 40px;
  @include sp {
    font-size: 19px;
  }
  &::before {
    content: '';
    width: 33px;
    height: 5px;
    background-color: #000;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    @include sp {
      width: 28px;
      height: 3px;
    }
  }
}
.text {
  padding-left: 40px;
  font-size: 18px;
  line-height: 1.8;
  @include sp {
    font-size: 14px;
  }
}

.boshu_catch_area {
  .container {
    position: relative;
  }
  .catch,
  .circle {
    position: absolute;
  }
  .catch {
    bottom: -50px;
    z-index: 1;
  }

  .circle {
    right: 0;
    bottom: -250px;
    @include sp {
      bottom: -160px;
    }
  }
}

.boshu_section {
  padding: 100px 0;
  background-color: #fff;
  @include sp {
    padding-bottom: 25px;
  }
  .em_text {
    font-size: 50px;
    font-weight: bold;
    background-image: url('../assets/text_wave_l.png');
    background-repeat: repeat-x;
    background-position-y: 100%;
    @include sp {
      font-size: 25px;
    }
  }
  .sub_em_text {
    font-size: 120%;
    font-weight: bold;
  }

  .store_lists {
    a {
      display: inline-block;
      border: 1px solid #000;
      padding: 1px 8px;
      margin-right: 10px;
      font-weight: bold;
      margin-bottom: 10px;
      &:after {
        content: '>';
      }
    }
  }
}

.miryoku_area {
  background-color: $rec_c;
  padding-bottom: 100px;
  .miryoku_cont_list {
    .miryoku_cont {
      display: flex;
      margin-bottom: 80px;
      .detail {
        a {
          border: 1px solid #000;
          padding: 10px 30px;
          border-radius: 25px;
          font-weight: bold;
          position: relative;
          img {
            display: block;
            right: 15px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
      .img {
        position: relative;
        flex-shrink: 0;
        .circle {
          @include sp {
            width: 170px;
            max-width: none;
          }
        }
        .catch {
          color: #fff;
          position: absolute;
          font-size: 40px;
          text-shadow: 0 0 1px #000;
          transform: rotate(-10deg);
          white-space: nowrap;
          font-weight: bold;
          @include sp {
            font-size: 20px;
          }
        }
      }
      &.cont1 {
        .detail {
          padding-right: 20px;
          @include sp {
            padding-top: 80px;
          }
          @include pc {
            padding-top: 170px;
          }
        }
        .circle {
          @include sp {
            margin-right: -30px;
          }
          @include pc {
            width: 334px;
          }
        }
        .catch {
          @include sp {
            left: -190px;
            top: 30px;
          }
          @include pc {
            left: -360px;
            top: 60px;
          }
        }
      }
      &.cont2 {
        .detail {
          padding-left: 20px;
          @include sp {
            padding-top: 60px;
          }
          @include pc {
            padding-top: 100px;
          }
        }
        .circle {
          @include sp {
            margin-left: -30px;
          }
          @include pc {
            width: 409px;
          }
        }
        .catch {
          @include sp {
            left: 80px;
          }
          @include pc {
            left: 290px;
            top: 0px;
          }
        }
      }
      &.cont3 {
        justify-content: flex-end;
        @include pc {
          margin-top: -200px;
        }
        .detail {
          padding-right: 20px;

          @include sp {
            padding-top: 60px;
          }
          @include pc {
            padding-top: 160px;
            width: 370px;
          }
        }
        .circle {
          @include sp {
            margin-right: -30px;
          }
          @include pc {
            width: 270px;
          }
        }
        .catch {
          line-height: 1.1;
          @include sp {
            left: -206px;
            top: -20px;
          }
          @include pc {
            left: -400px;
            top: 20px;
          }
        }
      }
      &:nth-child(2n) {
        .detail {
          order: 2;
        }
        .img {
          order: 1;
        }
      }
    }
  }
}

.system_area {
  background-color: #fff;
  padding-bottom: 100px;
  .recruit_table {
    margin: 20px 0;
    table-layout: fixed;
    @include sp {
      margin-left: -40px;
    }
    thead {
      th {
        text-align: center;
        font-size: 22px;
        padding: 10px 0;
        font-weight: bold;
        background-color: $rec_c;
        border-right: 2px solid #fff;
        border-left: 2px solid #fff;
        width: 30%;
      }
    }
    tbody {
      th {
        font-size: 14px;
        font-weight: bold;
        white-space: nowrap;
        padding: 20px;
        border-bottom: 1px solid $gray-300;
      }
      td {
        font-size: 14px;
        text-align: center;
        padding: 10px;
        border-bottom: 1px solid $gray-300;
      }
    }
  }
}

.career_list_area {
  display: flex;
  margin-left: -40px;
  .career_cont {
    flex: 1;
    text-align: center;
    position: relative;
    &:not(:last-child) {
      margin-right: 30px;
      &:after {
        content: '';
        background-image: url('../assets/rec_arrow.svg');
        width: 13px;
        height: 49px;
        display: inline-block;
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        right: -23px;
        bottom: -10px;
        @include sp {
          bottom: -21px;
        }
      }
    }
    img {
      width: 100px;
      height: 80px;
      object-fit: contain;
      object-position: 100% 100%;
      margin-bottom: 10px;
    }
    .career_step {
      display: block;
      background-color: $rec_c;
      padding: 15px 0;
      font-weight: bold;
      @include sp {
        padding: 9px 0;
        font-size: 10px;
      }
    }
  }
}

.interview_area {
  background-color: $rec_c;
  padding-bottom: 100px;
  @include sp {
    padding-bottom: 30px;
  }
  .interview_list {
    .interview_cont {
      background-color: #fff;
      margin-bottom: 100px;
      position: relative;
      padding: 25px;
      @include sp {
        margin-top: 200px;
      }
      @include pc {
        margin-right: 100px;
        display: flex;
        flex-direction: row-reverse;
      }
      &:nth-child(2n) {
        margin-right: auto;

        @include pc {
          margin-left: 100px;
          flex-direction: row;
        }
        .img {
          @include pc {
            margin-right: auto;
            margin-left: -180px;
            margin-top: 60px;
          }
        }
        .detail {
          padding-right: 0;
          @include pc {
            padding-left: 20px;
          }
        }
      }
      .img {
        @include pc {
          flex-shrink: 0;
          width: 350px;
          margin-right: -180px;
          margin-top: 60px;
        }
        @include sp {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: -150px;
          width: 160px;
        }
      }
      .detail {
        h3 {
          font-size: 30px;
        }
        @include pc {
          padding-right: 20px;
        }
      }
    }
  }
}

.row.apply_info_wrap {
  & > div {
    margin-bottom: 30px;
  }
  @include sp {
    flex-wrap: nowrap;
    overflow-x: scroll;
    & > div {
      width: 90%;
    }
  }
}

.apply_info_cont {
  border: 2px solid #000;
  padding: 20px;
  height: 100%;
  h3 {
    text-align: center;
  }
  table {
    margin-bottom: 15px;
    th {
      white-space: nowrap;
    }
    td {
      padding: 15px;
    }
  }
}

.wrap_apply_form {
  border: 2px solid;
  width: 700px;
  margin: auto;
  padding: 15px;
  background-color: #fff;
  max-width: 100%;
  iframe {
    width: 100%;
    display: block;
  }
}
</style>

<style lang="scss">
.recruit_slider {
  padding: 60px 0;
  overflow: hidden;
  h2 {
    text-align: center;
    color: #000;
    font-size: 20px;
    margin-bottom: 40px;
  }
  .slide_wrap {
    margin: auto;
    width: 635px;
    @include media-breakpoint-down(md) {
      width: 80%;
    }
    .slick-list {
      overflow: inherit;
    }

    .slick-arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      &.prev {
        left: -65px;
      }
      &.next {
        right: -65px;
      }

      @include media-breakpoint-down(md) {
        width: 17px;
        &.prev {
          left: -25px;
        }
        &.next {
          right: -25px;
        }
      }
    }
  }

  .slide {
    padding: 0 35px;
    @include media-breakpoint-down(md) {
      padding: 0 15px;
    }
    img {
      width: 635px;
      max-width: 100%;
      @include media-breakpoint-down(md) {
        width: 300px;
      }
    }
  }

  .slick-arrow {
    position: absolute;
    width: 60px;
    top: 40%;
    z-index: 10;
    @include media-breakpoint-down(md) {
      width: 30px;
    }
    &.prev {
      left: 0;
    }
    &.next {
      right: 0;
    }
  }
}
</style>

<template>
  <div class="amazake_area">
    <div class="main_catch">
      <img src="../assets/amazake_main.jpg" alt="" />
      <div class="main_catch_cont">
        <img src="../assets/amazake_logo.png" alt="" />
      </div>
    </div>

    <div class="amazake_nav">
      <a href="#amazake_concept">
        CONCEPT
      </a>
      <a href="#amazake_menu">
        MENU
      </a>
      <a href="#amazake_map">
        MAP
      </a>
    </div>

    <section id="amazake_concept" class="concept">
      <div class="container">
        <h2>
          CONCEPT
          <small>コンセプト</small>
        </h2>
        <p class="text-center">
          あま酒は、美白・美肌にも効果が期待できる、おいしくキレイになれるスーパードリンクです。「飲む点滴」と呼ばれるほど栄養価の高いドリンクとして、今注目を集めています。<br />
          <br />
          「あま酒研究所」では、美容や健康、さらには疲労回復の効果はもちろん、米麹からつくったあま酒をベースに、野菜やフレッシュフルーツなど、独自のブレンドでつくったあま酒を提供します。<br />
          <br />
          米麹本来の優しい風味とほのかな甘味、野菜やフレッシュフルーツの味わいが合わさり、ここでしか味わえない新感覚のあま酒を飲んで、身体の中からキレイになりませんか？
        </p>
      </div>
    </section>

    <section id="amazake_menu" class="menu">
      <div class="container">
        <h2>
          MENU
          <small>メニュー</small>
        </h2>
        <div class="row menu_list">
          <div class="col-lg-4" v-for="(menu, i) in menuItems" :key="i">
            <img :src="require('../assets/' + menu.img)" alt="" />
            <h3>{{ menu.name }}</h3>
            <p>
              {{ menu.text }}
            </p>
            <span class="price"> ¥{{ menu.price }} </span>
          </div>
        </div>
      </div>
    </section>

    <div class="amazake_map_area">
      <div id="amazake_map"></div>
    </div>

    <section class="">
      <div class="container">
        <h2>
          <img width="300" src="../assets/amazake_logo.png" alt="" />
        </h2>
        <table class="table">
          <tr>
            <th>店舗名称</th>
            <td>
              あま酒研究所
            </td>
          </tr>
          <tr>
            <th>住所</th>
            <td>
              〒101-0021 東京都千代田区外神田4-4-2 HULIC ＆ New AKIHABARA 10F<br />
              （JR山手線 秋葉原駅 徒歩5分）
            </td>
          </tr>
          <tr>
            <th>問い合わせ電話番号</th>
            <td>03-5244-4399</td>
          </tr>

          <tr>
            <th>営業時間</th>
            <td>
              平日　18:00～23:00（L.O 22:30）<br />
              土日　12:00〜16:00（L.O 15:30）<br />
              18:00～23:00（L.O 22:30）
            </td>
          </tr>
          <tr>
            <th>席</th>
            <td>座席 / 26席・立席 / 18席（合計:44席）</td>
          </tr>
          <tr>
            <th>提供期間</th>
            <td>2019年9月6日（金）〜2019年9月30日（月）</td>
          </tr>
          <tr>
            <th>注意事項</th>
            <td>
              ※KURAND SAKE MARKET
              秋葉原店のスペースを活用して営業するスタイルになります。<br />
              ※平日昼は、テイクアウト専用となりますが、店内に着席してお飲みいただくことも可能です。<br />
              ※平日夜、土日祝の昼夜は、日本酒専門店「KURAND SAKE
              MARKET」として営業します。<br />
              ※「KURAND SAKE
              MARKET」の営業時間内でも、同商品の注文（有料）は可能です。
            </td>
          </tr>
        </table>
      </div>
    </section>
  </div>
</template>
<script>
import GoogleMapsLoader from 'google-maps'
GoogleMapsLoader.KEY = 'AIzaSyBv017GNUrRqCjb9x0QEXbl3BXuhp1G99o'

export default {
  metaInfo() {
    return {
      title: 'あま酒研究所',
      meta: [
        {
          name: 'description',
          content:
            '「あま酒研究所」では、美容や健康、さらには疲労回復の効果はもちろん、米麹からつくったあま酒をベースに、野菜やフレッシュフルーツなど、独自のブレンドでつくったあま酒を提供します。',
        },
        {
          property: 'og:title',
          content: 'あま酒研究所',
        },
        {
          property: 'og:description',
          content:
            '「あま酒研究所」では、美容や健康、さらには疲労回復の効果はもちろん、米麹からつくったあま酒をベースに、野菜やフレッシュフルーツなど、独自のブレンドでつくったあま酒を提供します。',
        },
        {
          property: 'og:image',
          content:
            'https://kurand.jp/wp-content/uploads/2019/08/amazake-1-600x402.jpg',
        },
      ],
    }
  },
  data() {
    return {
      menuItems: [
        {
          img: 'normal_amazake.jpg',
          name: 'あま酒',
          text:
            '美白・美肌にも効果が期待できるスーパードリンク。優しい風味と、ほのかな甘味が楽しめます。',
          price: 400,
        },
        {
          img: 'tomato_amazake.jpg',
          name: 'とまとあま酒',
          text:
            '濃厚なあま酒にトマトの酸味をプラス。爽やかな飲み口が楽しめます。',
          price: 500,
        },
        {
          img: 'orange_amazake.jpg',
          name: 'オレンジあま酒',
          text: 'オレンジとシナモンの香りが口いっぱいに広がります。',
          price: 500,
        },
        {
          img: 'tounyu_amazake.jpg',
          name: '豆乳あま酒',
          text:
            '豆乳のコクときな粉の甘味が加わって、濃厚さNo.1。まろやかで優しい味わいです。',
          price: 500,
        },
        {
          img: 'yogurt_amazake.jpg',
          name: 'ヨーグルトあま酒',
          text:
            '「あま酒はこってりして苦手」という方にも美味しくお飲みいただける、爽やかな味わい。',
          price: 500,
        },
        {
          img: 'pine_amazake.jpg',
          name: 'あま酒パインフロート',
          text:
            'バニラアイス入りで、飲みやすさNo.1。パインの爽やかな甘みとあま酒が絡みます。',
          price: 500,
        },
      ],
    }
  },
  mounted() {
    this.makeMap(35.701338, 139.771835)
    // this.$nextTick(() => {
    //   document.dispatchEvent(new Event('x-app-rendered'))
    // })
  },
  methods: {
    makeMap(lat, lng) {
      GoogleMapsLoader.load(function(google) {
        const map = new google.maps.Map(
          document.getElementById('amazake_map'),
          {
            center: {
              lat: parseFloat(lat),
              lng: parseFloat(lng),
            },
            zoom: 20,
          },
        )

        new google.maps.Marker({
          position: {
            lat: parseFloat(lat),
            lng: parseFloat(lng),
          },
          map: map,
        })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'bootstrap-honoka/scss/honoka/_override.scss';

section {
  padding: 70px 0;
  h2 {
    text-align: center;
    font-weight: bold;
    font-size: 2rem;
    margin-bottom: 40px;
    small {
      display: block;
      font-size: 1.2rem;
    }
  }

  &.concept {
    background-image: url('../assets/amazake_concept_bg.jpg');
    background-size: cover;
    background-position: center;
  }
  &.menu {
    background-color: #fff;
  }
}

.main_catch {
  position: relative;
  & > img {
    width: 100%;
  }
  .main_catch_cont {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
      max-height: 600px;
    }
  }
}

.amazake_nav {
  background-color: #fff;
  display: flex;
  justify-content: center;
  a {
    padding: 20px;
  }
}

.menu_list {
  & > div {
    margin-bottom: 30px;
  }
  img {
    margin-bottom: 20px;
  }
  h3 {
    font-size: 1.5rem;
  }
}

.amazake_map_area {
  #amazake_map {
    width: 100%;
    height: 300px;
  }
}
</style>

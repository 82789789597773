<template>
  <div class="news_area">
    <div class="news_h">
      <span>NEWS</span>
    </div>
    <div class="news_list">
      <router-link
        v-for="(post, i) in newsPosts"
        :to="{ name: 'article', params: { id: post.id } }"
        :key="i"
      >
        {{ formatDate(post.date_gmt) }}
        <span v-html="post.title.rendered"></span>
      </router-link>

      <router-link class="text-right" :to="{ name: 'blog' }"
        >一覧を見る</router-link
      >
    </div>
  </div>
</template>

<script>
import blog from '../api/blog'
import moment from 'moment'

export default {
  data() {
    return {
      newsPosts: [],
    }
  },
  methods: {
    formatDate(date) {
      return moment(new Date(date)).format('YYYY.MM.DD')
    },
  },
  mounted() {
    blog
      .getList({ per_page: 3 })
      .then(response => {
        this.newsPosts = response.data
        this.reInit()
      })
      .catch(error => {
        // handle error
        console.log(error)
      })
  },
}
</script>

<style lang="scss">
.news_area {
  width: 800px;
  max-width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  .news_h {
    background-color: #000;
    color: #fff;
    font-size: 2.222rem;
    flex-basis: 170px;
    height: 170px;
    flex-shrink: 0;
    text-align: center;
    position: relative;
    @include media-breakpoint-down(lg) {
      flex-basis: 100px;
      height: 100px;
      font-size: 1.222rem;
    }
    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
    }
  }
  .news_list {
    padding: 20px;
    a {
      display: block;
      margin-bottom: 10px;
      font-size: 0.722rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>

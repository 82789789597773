<template>
  <div class="discount_faq">
    <h2>
      Q&A
      <small>よくある質問</small>
    </h2>

    <table class="discount_faq_table">
      <template v-for="(question, i) in questions">
        <tr :key="i">
          <td class="text-center">
            <a
              class="toggle_faq collapsed"
              data-toggle="collapse"
              :href="'#dis_faq' + i"
              role="button"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
            </a>
          </td>
          <td v-html="question.q"></td>
        </tr>
        <tr :id="'dis_faq' + i" class="collapse " :key="'dis_faq' + i">
          <td colspan="2" class="answer_area" v-html="question.a"></td>
        </tr>
      </template>
    </table>
  </div>
</template>
<script>
import 'bootstrap'
export default {
  data() {
    return {
      questions: [
        {
          q: '日本酒以外にもお酒はありますか？',
          a:
            '当店には、日本酒以外にも、日本酒の蔵元が造る果実感あふれる梅酒や和風リキュールも種類豊富に取り揃えています！定番のみかんやレモンといった柑橘系のリキュールから、ヨーグルト、栗、かぼちゃなどなど…ちょっと珍しいリキュールも数多く用意しています。また、発泡酒（サントリー金麦）も料金に含まれており、飲み放題となっています。',
        },
        {
          q: '何がお店に用意されていますか？',
          a:
            'お水、お水用コップ、電子レンジ、取り皿（プラスチック製）、お箸、スプーン、フォーク、おしぼり、お醤油、塩、コショウなどの調味料もございます。全て無料です。また、以下はご使用が可能ですので、気軽にお声がけくださいませ。<br/><br/>電子レンジ：スタッフに声掛けいただければ、暖めて席までお持ちします。お気軽にご利用ください。<br/>まな板・包丁：まな板と包丁はありますが、お客さま自身がご利用することはできません。スタッフに声掛けいただければ、カット、人数分の切り分けをします。お気軽にどうぞ。',
        },
        {
          q: '熱燗はすることができますか？',
          a: '酒燗器を用意しておりますので、熱燗もできます。',
        },
        {
          q: '電源はありますか？',
          a:
            'スマートフォンの充電器も用意しています。携帯の充電にお困りの方は、お気軽にお声掛けください。',
        },
        {
          q: 'Wifiはありますか？',
          a:
            '全店舗にて無料wifiを完備しております。レシートにパスワードが記載されていますので、自由にご利用いただけます。',
        },
        {
          q: '禁止行為はありますか？',
          a: `皆さまが日本酒を楽しむことができるように、以下を禁止行為とさせて頂いております。ご了承頂ければ幸いです。<br/>
<br/>
・お水のコップでお酒を飲まないこと。注がないこと。<br/>
・酒器にギリギリまで注がないこと。<br/>
・冷蔵庫前で、お酒を飲まないこと。必ずお席にお戻りください。<br/>
・吐くまで飲まない。倒れるまで飲まないこと。<br/>
・他のお客様に、迷惑をかけないこと。<br/>
・お酒のお持ち込みはご遠慮下さい。（ソフトドリンク類のお持ち込みは可能）<br/>
・当店は「禁煙」です。喫煙は店外にてお願いします。<br/>
・ビルの共有部分（エレベーター、階段、入口）に吐くと、特殊清掃代金4万円をお支払いいただくことになります。お気をつけください。<br/>
・飲み過ぎ・酔いすぎと店主が判断したら退場させていただきます。`,
        },
      ],
    }
  },
}
</script>
<style lang="scss" scoped>
.discount_faq {
  background-color: #f5f5f0;
  padding: 40px 30px;
  h2 {
    text-align: center;
    margin-bottom: 20px;
    small {
      display: block;
    }
  }

  .discount_faq_table {
    font-size: 1rem;
    width: 100%;
    border: 1px solid #ffbec8;
    td {
      padding: 10px;
      background-color: #ffbec8;
      border: 1px solid #fff;
      &.answer_area {
        background-color: #fff;
      }
    }

    .toggle_faq {
      &::before {
        content: '-';
      }
      &.collapsed::before {
        content: '+';
      }
    }
  }
}
</style>

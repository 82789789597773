<template>
  <section class="my_section">
    <div class="container py-4">
      <h2 class="text-center mb-4">
        NEWS
      </h2>
      <div class="row post_list_area">
        <div
          class="col-lg-4 post_list_cont"
          v-for="(post, i) in posts"
          :key="i"
        >
          <router-link :to="{ name: 'article', params: { id: post.id } }">
            <div class="thumb_area">
              <img :src="post.better_featured_image.source_url" alt="" />
            </div>
            <div class="detail_area">
              <span class="date">{{ formatDate(post.date) }}</span>
              <h3 v-html="post.title.rendered"></h3>
            </div>
          </router-link>
        </div>
      </div>
      <div class="row">
        <div class="col text-left">
          <router-link
            v-if="page - 1 > 0"
            :to="{
              name: 'blog',
              query: { page: page - 1 },
            }"
          >
            前のページへ
          </router-link>
        </div>
        <div class="col text-center">{{ page }} / {{ totalPages }}</div>
        <div class="col text-right">
          <router-link
            v-if="page < totalPages"
            :to="{
              name: 'blog',
              query: { page: page + 1 },
            }"
          >
            次のページへ
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapState, mapActions } from 'vuex'
export default {
  mounted() {
    this.getPosts(this.page)
  },
  watch: {
    // ルートが変更されたらこのメソッドを再び呼び出します
    $route() {
      this.getPosts(this.$route.query.page)
    },
  },
  methods: {
    ...mapActions('blog', ['getPosts']),
  },
  computed: {
    ...mapState('blog', [
      // map this.count to store.state.count
      'posts',
      'totalPages',
    ]),
    page() {
      return parseInt(this.$route.query.page) || 1
    },
  },
}
</script>

<style lang="scss" scoped>
.post_list_area {
  .post_list_cont {
    margin-bottom: 30px;

    a {
      color: #000;
      @include sp {
        display: flex;
        .thumb_area {
          flex-basis: 100px;
          flex-shrink: 0;
          margin-right: 10px;
        }
      }
      img {
        margin-bottom: 10px;
      }
      .date {
        display: block;
        margin-bottom: 10px;
        color: gs(2);
      }
      h3 {
        font-size: fz('base');
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        line-height: 1.6;
        height: 85px;
      }
      .description {
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
      }
    }
  }
}
</style>

import Vue from 'vue'
import Vuex from 'vuex'
import router from './router'
import sakemarketApi from './api/sakemarket'
import blogApi from './api/blog'

Vue.use(Vuex)

const blog = {
  namespaced: true,
  state: {
    nonce: null,
    post: {},
    posts: [],
    totalPosts: null,
    totalPages: null,
  },
  mutations: {
    setNonce(state, nonce) {
      state.nonce = nonce
    },
    setPostObj(state, post) {
      state.post = post
    },
    setPostList(state, posts) {
      state.posts = posts
    },
    setTotalPosts(state, total) {
      state.totalPosts = total
    },
    setTotalPages(state, total) {
      state.totalPages = total
    },
  },
  actions: {
    async getPost({ commit, state }, id) {
      // console.log('nonce', state.nonce)
      // if (!state.nonce) {
      //   await blogApi
      //     .getNonce()
      //     .then(response => {
      //       console.log(response)
      //       commit('setNonce', response.data.nonce)
      //     })
      //     .catch(error => {
      //       // handle error
      //       console.log(error)
      //     })
      // }

      commit('setPostObj', {})
      await blogApi
        .getOne(id, state.nonce)
        .then(response => {
          console.log(response)
          if (response) {
            commit('setPostObj', response.data)
          }
        })
        .catch(error => {
          // handle error
          console.log(error)
        })
    },
    getPosts({ commit }, page) {
      blogApi.getList({ page }).then(response => {
        console.log(response)
        console.log(response.headers['x-wp-totalpages'])
        commit('setPostList', response.data)

        commit('setTotalPages', response.headers['x-wp-totalpages'])
      })
    },
  },
}

export default new Vuex.Store({
  modules: {
    blog,
  },
  state: {
    modalShow: false,
    drawerOpened: false,
    storeObj: null,
    storeList: [],
    storeLoading: false,
    delayRender: false,
  },
  mutations: {
    delay(state) {
      state.delayRender = true
    },
    changeModal(state, flg) {
      state.modalShow = flg
    },
    changeDrawer(state, flg) {
      state.drawerOpened = flg
    },
    setStoreObj(state, storeObj) {
      state.storeObj = storeObj
    },
    setStoreList(state, storeList) {
      state.storeList = storeList
    },
    changeLoadStatus(state, status) {
      state.storeLoading = status
    },
  },
  actions: {
    async getStoreList({ commit }) {
      await sakemarketApi
        .getList()
        .then(response => {
          const storesData = response.data.filter(store => {
            store.title.rendered = store.title.rendered + '店'
            return store
          })
          console.log(storesData)
          commit('setStoreList', storesData)
        })
        .catch(error => {
          // handle error
          console.log(error)
        })
    },
    async getStoreData({ commit }, slug) {
      commit('changeLoadStatus', true)
      await sakemarketApi
        .getOne(slug)
        .then(response => {
          commit('changeLoadStatus', false)
          if (response.data.length) {
            if (response.data[0].store_cat.length) {
              const storeData = response.data[0]
              storeData.title.rendered = storeData.title.rendered + '店'
              commit('setStoreObj', storeData)
            } else {
              router.push('/')
            }
          } else {
            router.push('/')
          }
        })
        .catch(error => {
          // handle error
          console.log(error)
        })
    },
  },
})

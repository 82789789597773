import Vue from 'vue'
import moment from 'moment'

import VueMeta from 'vue-meta'
import App from './App.vue'
import router from './router'
import store from './store'
import jQuery from 'jquery'
// import VueAnalytics from 'vue-analytics'
//import VueGtm from 'vue-gtm'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'
import 'bootstrap/scss/bootstrap.scss'

global.jquery = jQuery
global.$ = jQuery
window.$ = window.jQuery = require('jquery')

Vue.config.productionTip = false

const requireComponent = require.context(
  // コンポーネントフォルダの相対パス
  './components',
  // サブフォルダ内を調べるかどうか
  false,
  // 基底コンポーネントのファイル名に一致させるのに使う正規表現
  /[A-Z]\w+\.(vue|js)$/,
)

requireComponent.keys().forEach(fileName => {
  // コンポーネント設定を取得する
  const componentConfig = requireComponent(fileName)

  // コンポーネント名をパスカルケース (PascalCase) で取得する
  const componentName = upperFirst(
    camelCase(
      // フォルダの深さに関わらずファイル名を取得する
      fileName
        .split('/')
        .pop()
        .replace(/\.\w+$/, ''),
    ),
  )
  // コンポーネントをグローバル登録する
  Vue.component(
    componentName,
    // `export default` を使ってコンポーネントがエクスポートされた場合に存在する
    // `.default` でコンポーネントオプションを期待していて
    // 存在しない場合にはモジュールのルートにフォールバックします。
    componentConfig.default || componentConfig,
  )
})

Vue.prototype.$isSp = $(window).width() < 992
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
})

// Vue.use(VueAnalytics, {
//   id: 'UA-37017393-3',
//   router,
// })

// Vue.use(VueGtm, {
//   id: 'GTM-MS5M5S', // Your GTM single container ID or array of container ids ['GTM-xxxxxxx', 'GTM-yyyyyyy']
//   enabled: false, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
//   debug: true, // Whether or not display console logs debugs (optional)
//   loadScript: false, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
//   vueRouter: router, // Pass the router instance to automatically sync with router (optional)
// })

Vue.mixin({
  mounted() {
    //this.$gtm.enable(true)
  },
  methods: {
    nl2br(str) {
      let res = str.replace(/\r\n/g, '<br>')
      res = res.replace(/(\n|\r)/g, '<br>')
      return res
    },
    formatDate(date) {
      return moment(date).format('YYYY.MM.DD')
    },
  },
})

new Vue({
  router,
  store,
  render: h => h(App),
  mounted: () => {
    // if (store.state.delayRender) {
    //   return
    // }
    // setInterval(() => {
    // }, 10000)
  },
}).$mount('#app')

router.beforeEach((to, from, next) => {
  //app.$gtm.enable(true)
  next()
})

<template>
  <div class="container mt-5">
    <div class="row justify-content-center">
      <div class="col-lg-9">
        <div class="bland_area">
          <div class="bland_catch">
            <img
              v-if="post.better_featured_image"
              :src="post.better_featured_image.source_url"
              alt=""
            />
          </div>
          <div class="bland_about">
            <h2 v-if="post.title" v-html="post.title.rendered"></h2>

            <social-btn></social-btn>

            <p>
              {{ formatDate(post.date) }}
            </p>
          </div>

          <div
            class="content_wrap"
            v-if="post.content"
            v-html="post.content.rendered"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import SocialBtn from '../components/SocialBtn'

export default {
  metaInfo() {
    return {
      title: this.pageTitle,
      meta: [
        {
          name: 'description',
          content: this.description,
        },
        {
          property: 'og:title',
          content: this.pageTitle,
        },
        {
          property: 'og:description',
          content: this.description,
        },
        {
          property: 'og:image',
          content: this.featuredImage,
        },
      ],
    }
  },
  data() {
    return {
      pageTitle: document.title,
      description: document.getElementsByName('description').length
        ? document.getElementsByName('description')[0].content
        : '',
      featuredImage: document.querySelector("meta[property='og:image']")
        ? document
            .querySelector("meta[property='og:image']")
            .getAttribute('content')
        : '',
    }
  },
  components: {
    SocialBtn,
  },
  async mounted() {
    this.$store.commit('delay')
    await this.getPost(this.$route.params.id)

    this.pageTitle = this.post.title.rendered
    this.description = this.post.excerpt.rendered
    this.featuredImage = this.post.better_featured_image.source_url
    this.$meta().refresh()
    this.$nextTick(() => {
      document.dispatchEvent(new Event('x-app-rendered'))
    })
  },
  methods: {
    ...mapActions('blog', ['getPost']),
  },
  computed: {
    ...mapState('blog', [
      // map this.count to store.state.count
      'post',
    ]),
  },
}
</script>
<style lang="scss">
.bland_area {
  .bland_catch {
    img {
      width: 100%;
    }
  }
  .bland_about {
    background-color: $gray-100;
    padding: 30px;
    position: relative;
    .prof_img {
      position: absolute;
      width: 50px;
      border-radius: 50%;
      overflow: hidden;
      top: -25px;
    }
    h2 {
      font-size: 2rem;
      font-weight: bold;
      margin-bottom: 1rem;
    }
    p {
      margin-bottom: 0;
    }
  }

  .nav-item {
    a.nav-link {
      border: none;
      color: $gray-500;
      font-size: 20px;
      @include media-breakpoint-down(sm) {
        font-size: 16px;
      }
      [class^='icon-'] {
        padding-right: 5px;
        font-size: 0.8em;
      }
      &.active {
        border-bottom: 2px solid;
        font-weight: bold;
        color: #000;
      }
    }
  }

  .bland_products_wrap {
    padding: 35px 0;
    @include media-breakpoint-down(sm) {
      padding: 30px;
    }

    .row {
      @include media-breakpoint-down(sm) {
      }
    }

    h2 {
      font-size: 1.5rem;
      font-weight: bold;
      margin-bottom: 1rem;
    }

    h3 {
      font-size: 1rem;
      font-weight: bold;
      margin-bottom: 1rem;
    }

    .bland_product_sum {
      overflow: hidden;
      position: relative;
      margin-bottom: 15px;
      & > img {
        object-fit: cover;
        max-height: 100%;
        height: 100%;
      }
    }

    .bland_product_thumbnails {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      & > div {
        width: 23%;
        margin-bottom: 10px;
        @include media-breakpoint-down(sm) {
          width: 48%;
        }
        img.wp-post-image {
          border: 1px solid;
        }
      }
    }

    .detail_area {
      .description {
        color: $gray-700;
        line-height: 1.8;
        margin-bottom: 1rem;
        .red {
          color: $danger;
        }
      }

      .quantity {
        max-width: 80px;
        label {
          font-size: 0.9rem;
        }
      }
    }
  }
}

.content_wrap {
  padding: 25px;
  font-size: fz('base');
  h2 {
    font-size: fz('lead2');
    font-weight: 700;
    margin-bottom: 2rem;
    margin-top: 3rem;
    border-left: 1.2em solid;
    padding: 0.1rem 1rem;
  }
  h3 {
    font-size: fz('lead1');
    font-weight: bold;
    margin-bottom: 1.5rem;
    margin-top: 2rem;
    border-bottom: 1px solid;
    padding-bottom: 0.5rem;
  }

  img {
    height: auto;
    &.aligncenter {
      margin: auto;
      display: block;
    }
  }

  p {
    line-height: 200%;
    margin-bottom: 2rem;
    font-size: fz('base');
  }

  table {
    @extend .table;
    th {
      @include media-breakpoint-up(sm) {
        white-space: nowrap;
      }
    }
  }

  a {
    text-decoration: underline;
    word-break: break-all;
    &.btn {
      text-decoration: none;
    }
  }

  .user_voice_area {
    background-color: $gray-100;
    padding: 20px;
    padding-bottom: 40px;
    margin-bottom: 40px;
    position: relative;
    font-style: italic;
    .name {
      position: absolute;
      right: 20px;
      bottom: 20px;
      font-weight: bold;
    }
  }

  .mokuji {
    text-align: center;
    border-top: 1px solid $gray-200;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .midashi_area {
    padding-bottom: 20px;
    border-bottom: 1px solid $gray-200;
  }

  .red {
    color: $danger;
  }

  iframe {
    max-width: 100%;
  }

  .line_box {
    border: 1px solid $gray-200;
    padding: 20px;
    p:last-child {
      margin-bottom: 0;
    }
  }
}
</style>

<template>
  <section class="instagram">
    <div class="container">
      <h2>Instagram</h2>
      <div class="insta_account">
        <a href="https://www.instagram.com/sakemarket/">
          <img width="50" src="../assets/insta_logo.png" alt="" />
        </a>
        <span>
          <a href="https://www.instagram.com/sakemarket/">
            sakemarket
          </a>
        </span>
      </div>

      <div class="insta_wrap row">
        <div class="col-lg-3 col-6" v-for="(photo, i) in instaPhotos" :key="i">
          <a :href="photo.permalink" :style="{ height: fixHeight() + 'px' }">
            <img :src="photo.media_url" alt="" />
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      instaPhotos: [],
    }
  },
  mounted() {
    axios
      .get(
        'https://graph.facebook.com/v7.0/17841407230647709?fields=name,media{caption,like_count,media_url,permalink,timestamp,username}&access_token=EAAJEYF28QZAsBAJED5EQ0THhyYtIZBg7tLGZAx4wIMBSZC8XqobeUQxw2ycNZBmbWRFZBT4oZBkwtSLSiuCydDuFJEjGNdZARB7LovI9jL5FZC1V2zxiZCnApNIRzbajE9IYkNVWqjo9NhM2ueBzzmA3SSZCC2lSKOGMioyIuOrYJcze2zAHZCsuGiKr&max=8',
      )
      .then(response => {
        console.log(response.data)
        this.instaPhotos = response.data.media.data.splice(0, 8)
      })
      .catch(error => {
        // handle error
        console.log(error)
      })

    // axios
    //   .get(
    //     'https://api.instagram.com/v1/users/self/media/recent/?access_token=751475987.92cb127.6084f23850044d96b6f262d1facb6736&count=8',
    //   )
    //   .then(response => {
    //     this.instaPhotos = response.data.data
    //   })
    //   .catch(error => {
    //     // handle error
    //     console.log(error)
    //   })
  },
  methods: {
    fixHeight() {
      return $('.insta_wrap>div a').width()
    },
  },
}
</script>

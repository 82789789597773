<template>
  <div class="home">
    <top-catch></top-catch>
    <news></news>
    <concepts />
    <ec-banners />
    <item-list
      title="日本酒"
      list_img="sake_bin_list.png"
      alt="日本酒専門店・日本酒バーのKURANDSAKEMARKETの飲める日本酒"
      url="https://kurand.jp/collections/sake"
    ></item-list>
    <campaign></campaign>
    <system></system>
    <howto />
    <item-list
      title="果実酒"
      list_img="liquor_bin_list.png"
      alt="日本酒専門店・日本酒バーのKURANDSAKEMARKETの飲める果実酒"
      url="https://kurand.jp/collections/liquor"
    ></item-list>
    <store-list />
    <instagram />

    <!-- <transition v-if="modalFlg" name="modal">
      <div
        class="modal_mask"
        v-if="modalOpend"
        @click.self="modalOpend = false"
      >
        <div class="top_modal">
          <a class="close_btn" href="#" @click.prevent="modalOpend = false"
            ><i class="fas fa-times"></i
          ></a>
          <a href="https://kurand.jp/pages/sakegacha-spring">
            <img
              src="https://cdn.shopify.com/s/files/1/0075/9909/5863/files/sakegacha_spring_modal2022.jpg?v=1647429726"
              alt=""
            />
          </a>
        </div>
      </div>
    </transition> -->
  </div>
</template>

<script>
import moment from 'moment'

export default {
  metaInfo() {
    return {
      title: '日本酒専門店・日本酒バー！SAKE MARKET',
      meta: [
        {
          name: 'description',
          content:
            '新しい日本酒との出会い。「SAKE MARKET」は日本酒100種類飲み放題、時間無制限、持ち込み自由が特長の日本酒専門店・日本酒バーです。新宿、秋葉原に日本酒専門店を展開中。時間とお金を気にせず日本酒をお楽しみください。土日祝日は、昼飲みも展開中。',
        },
        {
          property: 'og:title',
          content:
            '日本酒専門店・日本酒バーSAKEMARKET　日本酒100種類、飲み放題、時間無制限、持ち込み自由',
        },
        {
          property: 'og:description',
          content:
            '新しい日本酒との出会い。「SAKE MARKET」は日本酒100種類飲み放題、時間無制限、持ち込み自由が特長の日本酒専門店・日本酒バーです。新宿、秋葉原に日本酒専門店を展開中。時間とお金を気にせず日本酒をお楽しみください。土日祝日は、昼飲みも展開中。',
        },
        {
          property: 'og:image',
          content: 'https://api.kurand.jp/app/uploads/2023/01/newsakema.jpg',
        },
        {
          name: 'thumbnail',
          content: 'https://api.kurand.jp/app/uploads/2023/01/newsakema.jpg',
        },
      ],
    }
  },
  data() {
    const modalFlg = moment().isBefore(new Date('2022-05-09'))
    return {
      modalOpend: false,
      modalFlg,
    }
  },
  mounted() {
    document.dispatchEvent(new Event('x-app-rendered'))
    setTimeout(() => {
      this.modalOpend = true
    }, 1000)
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
.top_modal {
  position: relative;
  z-index: 101;

  background-color: #fff;

  @include media-breakpoint-up(lg) {
    top: 50%;
    left: 50%;
    width: 480px;
    max-width: 100%;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
  }
  @include media-breakpoint-down(lg) {
    top: 50%;
    transform: translateY(-50%);
    margin: 0 60px;
  }
  .close_btn {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    z-index: 1;
  }
}
</style>

<template>
  <ul class="socialbtn_area">
    <li>
      <div
        class="fb-like"
        :data-href="url"
        data-layout="button"
        data-action="like"
        data-size="small"
        data-show-faces="false"
        data-share="false"
      ></div>
    </li>
    <li id="tweet-area"></li>
    <li>
      <div
        class="line-it-button"
        data-lang="ja"
        data-type="share-a"
        :data-url="url"
        style="display: none;"
      ></div>
    </li>
  </ul>
</template>
<script>
export default {
  data() {
    return {
      fbShown: false,
      url: location.href,
    }
  },
  mounted() {
    this.fbShown = true
    if (window.twttr) {
      window.twttr.widgets.createShareButton(
        '',
        document.getElementById('tweet-area'),
        {
          lang: 'ja',
          url: this.url,
        },
      )
    }

    if (!document.getElementById('facebook-jssdk')) {
      ;(function(d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0]
        if (d.getElementById(id)) return
        js = d.createElement(s)
        js.id = id
        js.src =
          'https://connect.facebook.net/ja_JP/sdk.js#xfbml=1&version=v3.0&appId=1895556074055876&autoLogAppEvents=1'
        fjs.parentNode.insertBefore(js, fjs)
      })(document, 'script', 'facebook-jssdk')
    }

    if (window.LineIt) {
      window.LineIt.loadButton()
    }
  },
}
</script>
<style lang="scss">
.fb_iframe_widget > span {
  vertical-align: baseline !important;
}
</style>
<style lang="scss" scoped>
.socialbtn_area {
  padding: 0;
  display: flex;
  li {
    list-style: none;
    margin-right: 10px;
  }
}
</style>

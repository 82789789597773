<template>
  <section class="campaign" v-if="campaigns.length">
    <div class="container">
      <h2>期間限定カクテルのご注文はスタッフまで！</h2>
      <div class="row campaign_banners justify-content-center">
        <div class="col-lg-6 mb-4" v-for="(campaign, i) in campaigns" :key="i">
          <router-link
            :to="{ name: 'article', params: { id: campaign.acf.url.ID } }"
          >
            <img :src="campaign.acf.img_pc" :alt="campaign.title.rendered" />
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import sakemarket from '../api/sakemarket'

export default {
  data() {
    return {
      campaigns: [],
    }
  },
  mounted() {
    sakemarket
      .getCampaigns()
      .then(response => {
        this.campaigns = response.data
      })
      .catch(error => {
        // handle error
        console.log(error)
      })
  },
}
</script>

<style lang="scss">
section.campaign {
  h2 {
    background-image: url('../assets/campaign_h_bg.png');
    font-size: 1.333rem;
    background-repeat: no-repeat;
    background-position-x: 50%;
    padding-top: 29px;
    padding-bottom: 45px;
    background-size: contain;
    text-align: center;
    @include media-breakpoint-down(lg) {
      font-size: 1rem;
      padding-top: 18px;
      margin-bottom: 0;
    }
  }
}
</style>

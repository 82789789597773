<template>
  <section class="system">
    <h2>SYSTEM</h2>
    <div class="system_wrap">
      <img
        class="system_img left"
        src="../assets/system_catch01.jpg"
        alt="日本酒専門店・日本酒バーのKURANDSAKEMARKETの冷蔵庫写真"
      />
      <div class="container">
        <div class="row justify-content-end">
          <div class="col-lg-6">
            <div class="system_cont">
              <h3>
                日本酒、果実酒、梅酒、焼酎約100種類<br />セルフスタイルで飲み比べし放題
              </h3>
              <p>
                全国各地の酒蔵と共同開発した日本酒、果実酒、梅酒、焼酎を約100種類取り揃えている当店では、好きな量を自由に飲み比べできるよう、セルフスタイルを採用しています。お会計後にお好きな酒器を3つ選んで、冷蔵庫の中から気になるお酒を手に取って飲み比べてみてください。
              </p>
              <p>
                About 100 kinds of sake, fruit wine, plum wine, shochu, etc.
                All-you-can-drink self-service style! We offer about 100 kinds
                of sake, fruit wine, plum wine, shochu, etc. from all over
                Japan. After paying your bill, choose three of your favorite
                sake cups, pick up the sake you are interested in from the
                refrigerator, and compare them.
              </p>
              <div class="system_box">
                <strong>店内にご用意があるもの</strong>
                日本酒、焼酎、梅酒、果実酒約100種類・発泡酒（サントリー金麦）・ソフトドリンク（烏龍茶・緑茶・紅茶・オレンジジュース・グレープフルーツジュースなど）・ソーダ・水・氷<br />
                Sake, shochu, plum wine, fruit wine (about 100 kinds), beer,
                soft drinks (oolong tea, green tea, black tea, orange juice,
                grapefruit juice, etc.), soda, water, ice.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="system_wrap">
      <img
        class="system_img right"
        src="../assets/system_catch02.jpg"
        alt="日本酒専門店・日本酒バーのKURANDSAKEMARKETのイメージ写真"
      />
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="system_cont">
              <h3>お料理は持ち込み・デリバリーで調達！</h3>
              <p>
                当店は前払い制なので、途中入退店OK！盛り付け用のお皿や電子レンジ・簡単な調理器具も完備しているので、家飲み感覚で買い出し・デリバリーが可能です。
              </p>
              <p>
                You can bring your own food or have it delivered! Our restaurant
                is prepaid, so you are free to come and go as you please! We are
                equipped with plates, microwaves, and simple cooking utensils,
                so you can purchase and deliver food as if you were drinking at
                home.
              </p>
              <small>
                ※外出の際はスタッフまでお声がけください。<br />
                ※どのお店にデリバリーが可能かは、スタッフにお尋ねください。<br />
                ※店内に匂いが充満するものの持ち込みはご遠慮ください。<br />
                ※店内にも簡単なおつまみをご用意しています。レジで販売していますので、ご活用ください。
              </small>
              <div class="system_box">
                <strong>店内にご用意があるもの</strong>
                電子レンジ・お皿・お箸・フォーク・スプーン・お醤油などの調味料・まな板・包丁
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {}
</script>

<style lang="scss">
.system {
  padding: 40px 0;
  h2 {
    text-align: center;
    margin-bottom: 90px;
    img {
      display: block;
      margin: auto;
      margin-bottom: 20px;
    }
  }
  .system_wrap {
    background-color: #fff;
    justify-content: end;
    padding: 66px 0;
    position: relative;

    &:not(:last-child) {
      margin-bottom: 120px;
    }
    @include media-breakpoint-down(lg) {
      padding-top: 0;
    }

    .system_img {
      display: block;
      position: absolute;
      @include media-breakpoint-down(lg) {
        position: relative;
        width: 80%;
      }
      top: -40px;
      &.left {
        left: 0;
      }
      &.right {
        right: 0;
        margin-left: auto;
      }
    }
    .row {
      .system_cont {
        h3 {
          font-size: 1.777rem;
          line-height: 1.8;
          font-weight: 100;
          font-family: $font-family-serif;
        }
        p {
          line-height: 1.8;
        }
        small {
          display: block;
          margin-bottom: 40px;
        }
        .system_box {
          position: relative;
          border: 2px solid #f4f4f4;
          padding: 18px;
          font-size: 0.888rem;
          strong {
            position: absolute;
            background-color: #fff;
            top: -10px;
            left: 0;
          }
        }
      }
    }
  }
}
</style>

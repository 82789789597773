<template>
  <section class="howto">
    <div class="container">
      <h2>
        <!-- <img src="../assets/kakuban_h.png" alt="" /> -->
        <img width="400" src="../assets/key_text.svg" alt="" />
      </h2>
      <h3>
        自分好みのお酒に出会おう
      </h3>
      <template v-if="this.$isSp">
        <img class="mb-5" src="../assets/howto_sp01.png" alt="" />
        <img class="mb-5" src="../assets/howto_sp02.png" alt="" />
        <img class="mb-5" src="../assets/howto_sp03.png" alt="" />
      </template>

      <template v-if="!this.$isSp">
        <div class="text-center mb-5">
          <img
            src="../assets/howto01.jpg"
            alt="日本酒専門店・日本酒バーのKURANDSAKEMARKETの冷蔵庫写真②"
          />
        </div>
        <div class="row">
          <div class="col-lg-3">
            <img src="../assets/howto_ico01.png" alt="" />
            <p>直感を信じて、<br />気になるお酒をジャケ飲み！</p>
          </div>
          <div class="col-lg-3">
            <img src="../assets/howto_ico02.png" alt="" />
            <p>メニューリストから<br />好みの味わいを<br />選んでみよう！</p>
          </div>
          <div class="col-lg-3">
            <img src="../assets/howto_ico03.png" alt="" />
            <p>
              お酒大好き！<br />勉強熱心なスタッフに、<br />おすすめを聞いてみよう！
            </p>
          </div>
          <div class="col-lg-3">
            <img src="../assets/howto_ico04.png" alt="" />
            <p>
              世界が広がる・・！<br />いろんな温度帯で飲んでみよう<br />酒燗器があるよ
            </p>
          </div>
        </div>
      </template>
      <h3>
        料理とのペアリングを楽しんでみよう
      </h3>

      <template v-if="this.$isSp">
        <img class="mb-5" src="../assets/howto_sp04.png" alt="" />
        <img class="mb-5" src="../assets/howto_sp05.png" alt="" />
      </template>

      <template v-if="!this.$isSp">
        <div class="text-center mb-5">
          <img
            src="../assets/howto02.jpg"
            alt="日本酒専門店・日本酒バーのKURAND SAKE MARKETの料理とのペアリングイメージ"
          />
        </div>

        <div class="row">
          <div class="col-lg-4">
            <img src="../assets/howto_ico05.png" alt="" />
            <p>最寄駅のデパ地下や<br />コンビニで料理を調達！</p>
          </div>
          <div class="col-lg-4">
            <img src="../assets/howto_ico06.png" alt="" />
            <p>
              簡単な調理用具もあります！
            </p>
          </div>
          <div class="col-lg-4">
            <img src="../assets/howto_ico07.png" alt="" />
            <p>店内にも簡単なおつまみ・<br />缶詰、ございます。</p>
          </div>
        </div>
      </template>

      <!-- <h3>
        訪問レポート
      </h3>
      <div class="row justify-content-center">
        <div class="col-lg-4  mb-5">
          <component :is="tweet" id="1147141457901260801"></component>
        </div>
        <div class="col-lg-4 mb-5">
          <component :is="tweet" id="1132141614946693121"></component>
        </div>

        <div class="col-lg-4 mb-5">
          <component :is="tweet" id="1149614179113820161"></component>
        </div>
        <div class="col-lg-4 mb-5">
          <component :is="tweet" id="1155048024147550208"></component>
        </div>

        <div class="col-lg-4 mb-5">
          <component :is="tweet" id="1214849945657757696"></component>
        </div>

        <div class="col-lg-4 mb-5">
          <component :is="tweet" id="1215468437784252417"></component>
        </div>

        <div class="col-lg-6 text-center mb-5">
          <div class="embed-responsive embed-responsive-16by9 mx-auto">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/wNsJjZv27CI"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>

        <div class="col-lg-6 text-center mb-5">
          <div class="embed-responsive embed-responsive-16by9 mx-auto">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/fYuGXwkqqiA"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div> -->
    </div>
  </section>
</template>

<script>
import { Tweet } from 'vue-tweet-embed'
export default {
  components: {
    //Tweet: () => import('vue-tweet-embed/src/tweet'),
    Tweet,
  },
  data() {
    return {
      tweet: null,
    }
  },
  mounted() {
    this.tweet = 'Tweet'
  },
}
</script>

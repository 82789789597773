<template>
  <div>
    <div class="store_area">
      <top-catch></top-catch>
      <concepts />
      <!-- <banners /> -->
      <system></system>

      <campaign></campaign>

      <section class="about" v-if="storeObj">
        <h2>
          <img src="../assets/foot_logo.svg" alt="" />
          {{ storeObj.title.rendered }}
        </h2>
        <div class="container">
          <table class="store_table">
            <tr>
              <th>
                住所
              </th>
              <td v-html="storeObj.acf.address.replace('<br />', ' ')"></td>
            </tr>
            <tr>
              <th>
                料金
              </th>
              <td>時間無制限 3,600円（税込）</td>
            </tr>
            <!-- <tr>
              <th>
                問い合わせ電話番号
              </th>
              <td>{{ storeObj.acf.tel }}</td>
            </tr> -->
            <!-- <tr>
              <th>
                予約専用電話番号
              </th>
              <td>{{ storeObj.acf.reserve_tel }}</td>
            </tr> -->
            <tr>
              <th>
                メール
              </th>
              <td>{{ storeObj.acf.mail }}</td>
            </tr>
            <tr>
              <th>
                営業時間
              </th>
              <td v-html="nl2br(storeObj.acf.opening)"></td>
            </tr>
            <tr>
              <th>
                席
              </th>
              <td>
                {{ storeObj.acf.capacity }}
              </td>
            </tr>
            <tr v-if="storeObj.acf.opening !== '現在休業中'">
              <th>
                休み
              </th>
              <td>
                定休日なし
              </td>
            </tr>
            <tr>
              <th>
                電話番号
              </th>
              <td>
                <a :href="'tel:' + storeObj.acf.tel">{{ storeObj.acf.tel }}</a>
              </td>
            </tr>
            <tr>
              <th>
                地図
              </th>
              <td>
                <a :href="storeObj.acf.url">GoogleMapページへ</a>
              </td>
            </tr>
            <tr v-if="storeObj.acf.tabelog">
              <th>
                食べログURL
              </th>
              <td>
                <a :href="storeObj.acf.tabelog">{{ storeObj.acf.tabelog }}</a>
              </td>
            </tr>
            <tr>
              <th>
                アクセス
              </th>
              <td v-html="storeObj.acf.how_arrive"></td>
            </tr>
          </table>

          <div id="store_map"></div>
        </div>
      </section>

      <section class="attention">
        <div class="container">
          <div class="row ico_area text-center">
            <div class="col-lg-3 col-6">
              <img src="../assets/ico_wifi.png" alt="" />
            </div>
            <div class="col-lg-3 col-6">
              <img src="../assets/ico_power.png" alt="" />
            </div>
            <div class="col-lg-3 col-6">
              <img src="../assets/ico_credit.png" alt="" />
            </div>
            <div class="col-lg-3 col-6">
              <img src="../assets/ico_sp.png" alt="" />
            </div>
          </div>

          <div class="attention_area">
            <h3>
              ご遠慮いただきたいこと
            </h3>
            <div class="row">
              <div class="col-lg-6">
                <p>
                  店内での喫煙
                </p>
                <small>※喫煙は店外にてお願いします。</small>
              </div>
              <div class="col-lg-6">
                <p>
                  泥酔状態でのご来店
                </p>
              </div>
              <div class="col-lg-6">
                <p>
                  アルコール類のお持ち込み
                </p>
              </div>
              <div class="col-lg-6">
                <p>
                  一気飲みなどの危険な飲み方・過度な飲酒
                </p>
                <small
                  >※店内での嘔吐等泥酔状態と判断した場合、ご退店いただく場合がございます。</small
                >
              </div>
            </div>

            <h3>
              その他注意事項
            </h3>
            <div class="other_attention">
              ※お持ち込みいただいた食事や酒器に関してのトラブルは一切の責任を負いかねます。<br />
              ※当店を利用する前にあまりにお酒を飲まれている場合、入店をお断りする場合が御座います。<br />
              ※授乳中のお子様、未成年のお客様をお連れしてのご入店はお断りさせていただいております。<br />
              ※店内での外部へ対する動画撮影や生放送（WEB配信）は他のお客様のご迷惑となるため、禁止しております。<br />
              ※お客様同士のトラブル等について、当社は一切の補償及び関与をいたしません。
            </div>
          </div>
        </div>
      </section>
    </div>
    <store-list />
    <instagram />
  </div>
</template>

<script>
import Campaign from '../components/Campaign'
import System from '../components/System'

export default {
  metaInfo() {
    return {
      title: this.pageTitle,
      meta: [
        {
          name: 'description',
          content: this.description,
        },
        {
          property: 'og:title',
          content: this.pageTitle,
        },
        {
          property: 'og:description',
          content: this.description,
        },
        {
          property: 'og:image',
          content: this.featuredImage,
        },
        {
          name: 'thumbnail',
          content: this.featuredImage,
        },
      ],
    }
  },
  data() {
    return {
      campaigns: [],
      pageTitle: document.title,
      description: document.getElementsByName('description').length
        ? document.getElementsByName('description')[0].content
        : '',
      featuredImage: document.querySelector("meta[property='og:image']")
        ? document
            .querySelector("meta[property='og:image']")
            .getAttribute('content')
        : '',
    }
  },
  components: {
    Campaign,
    System,
  },
  created() {},
  async mounted() {
    this.$store.commit('delay')
    scrollTo(0, 0)
    await this.$store.dispatch('getStoreData', this.$route.params.slug)

    //document.title = this.storeObj._aioseop_title
    this.pageTitle = this.storeObj._aioseop_title
    this.description = this.storeObj._aioseop_description
    this.featuredImage = this.storeObj.better_featured_image.source_url
    this.$meta().refresh()
    this.makeMap(this.storeObj.acf.map.lat, this.storeObj.acf.map.lng)
    this.$nextTick(() => {
      document.dispatchEvent(new Event('x-app-rendered'))
    })
  },
  methods: {
    makeMap(lat, lng) {
      const map = new window.google.maps.Map(
        document.getElementById('store_map'),
        {
          center: {
            lat: parseFloat(lat),
            lng: parseFloat(lng),
          },
          zoom: 20,
        },
      )

      new window.google.maps.Marker({
        position: {
          lat: parseFloat(lat),
          lng: parseFloat(lng),
        },
        map: map,
      })
    },
  },
  watch: {
    async $route(to) {
      scrollTo(0, 0)
      await this.$store.dispatch('getStoreData', to.params.slug)
      this.pageTitle = this.storeObj._aioseop_title
      this.description = this.storeObj._aioseop_description
      this.featuredImage = this.storeObj.better_featured_image.source_url
      this.makeMap(this.storeObj.acf.map.lat, this.storeObj.acf.map.lng)
    },
  },
  computed: {
    storeObj() {
      console.log(this.$store.state.storeObj)
      return this.$store.state.storeObj
    },
  },
}
</script>

<style lang="scss">
#store_map {
  margin: auto;
  margin-top: 30px;

  width: 840px;
  max-width: 100%;
  height: 400px;
}

.about {
  h2 {
    width: 292px;
    max-width: 100%;
    margin: auto;
  }
}

.store_area {
  section {
    padding: 40px 0;
    h2 {
      text-align: center;
      margin-bottom: 90px;
      img {
        display: block;
        margin: auto;
        margin-bottom: 20px;
      }
    }
    .store_table {
      width: 840px;
      max-width: 100%;
      margin: auto;
      @include media-breakpoint-down(lg) {
        width: 100%;
      }
      tr {
        border-bottom: 1px solid rgba($color: #000, $alpha: 0.4);
      }
      th {
        white-space: nowrap;
      }
      td {
        word-break: break-all;
      }
      th,
      td {
        padding: 20px;
      }
    }
  }
}

.attention {
  .ico_area {
    margin-bottom: 100px;
    & > div {
      margin-bottom: 30px;
      padding: 0 30px;
    }
  }
  .attention_area {
    background-color: #ededed;
    width: 800px;
    max-width: 100%;
    margin: auto;
    padding: 50px;
    h3 {
      font-size: 1rem;
      text-align: center;
      margin-bottom: 50px;
      @include media-breakpoint-down(lg) {
        text-align: center;
      }
    }
    .row {
      @include media-breakpoint-down(lg) {
        text-align: center;
      }
      & > div {
        margin-bottom: 50px;
        p {
          margin: 0;
        }
      }
    }

    .other_attention {
      font-size: 0.888rem;
    }
  }
}
</style>

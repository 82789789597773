import apiBase from './base'

export default {
  getCampaigns() {
    return apiBase.get('/wp-json/wp/v2/slider')
  },
  getEcCampaigns() {
    return apiBase.get('/wp-json/wp/v2/ec_slider')
  },
  getList() {
    return apiBase.get('/wp-json/wp/v2/sakemarket?store_cat=228')
  },
  getOne(id) {
    return apiBase.get('/wp-json/wp/v2/sakemarket?slug=' + id).catch(error => {
      // handle error
      console.log(error)
    })
  },
}

<template>
  <div class="item_list">
    <h2>
      PRODUCT
    </h2>
    <h3>
      {{ title }}
    </h3>
    <div class="item_list_wrap">
      <img :src="require('../assets/' + list_img)" :alt="alt" />
    </div>

    <!-- <a :href="url" class="my_btn mt-4"> {{ title }}を購入する </a> -->
  </div>
</template>
<script>
export default {
  props: ['title', 'list_img', 'url', 'alt'],
}
</script>

<style lang="scss">
.item_list {
  padding-top: 90px;
  margin-bottom: 150px;
  @include media-breakpoint-down(lg) {
    margin-bottom: 70px;
  }
  h2 {
    text-align: center;
    font-size: 2.222rem;
    line-height: 160%;
  }
  h3 {
    text-align: center;
    font-size: 1.888rem;
    margin-bottom: 70px;
  }
  .item_list_wrap {
    padding-bottom: 70px;

    overflow-x: scroll;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    img {
      width: auto;
      max-width: none;
      @include media-breakpoint-down(lg) {
        height: 120px;
      }
    }
    & > * {
      //margin: 0 30px;
    }
    &::-webkit-scrollbar {
      height: 20px;
    }
    &::-webkit-scrollbar-track {
      background: #cccccc;
      border-left: solid 1px #ececec;
    }
    &::-webkit-scrollbar-thumb {
      background: #4d4d4d;
      border-radius: 10px;
      width: 20px;
    }
  }
}
</style>

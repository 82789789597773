import axiosBase from 'axios'

const BaseConfig = {
  baseURL: process.env.VUE_APP_API_URL_BASE,
}

console.log(getParam('access_token'))

if (getParam('access_token')) {
  BaseConfig.headers = {
    Authorization: `Bearer ${getParam('access_token')}`,
  }
}

function getParam(name, url) {
  if (!url) url = window.location.href
  // eslint-disable-next-line no-useless-escape
  name = name.replace(/[\[\]]/g, '\\$&')
  var regex = new RegExp('[?&#]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

export default axiosBase.create(BaseConfig)

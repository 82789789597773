import apiBase from './base'

export default {
  getNonce() {
    return apiBase.get('/wp-nonce.php')
  },
  getEvents() {
    return apiBase.get('/wp-json/wp/v2/posts?tags=1458')
  },
  getList(params) {
    return apiBase.get('/wp-json/wp/v2/posts', {
      params: {
        category: 1152,
        tags: 115,
        per_page: 12,
        ...params,
      },
    })
  },
  getOne(id) {
    return apiBase.get('/wp-json/wp/v2/posts/' + id).catch(error => {
      // handle error
      console.log(error)
    })
  },
}

<template>
  <div class="event_slide">
    <slick ref="slick" :options="slickOptions">
      <div class="event_slide_cont" v-for="(event, i) in events" :key="i">
        <div class="row no-gutters">
          <div class="col-4">
            <router-link :to="{ name: 'article', params: { id: event.id } }">
              <img :src="event.better_featured_image.source_url" alt="" />
            </router-link>
          </div>
          <div class="col-8 event_detail">
            <router-link :to="{ name: 'article', params: { id: event.id } }">
              <h2>EVENT</h2>
              <h3>{{ event.title.rendered }}</h3>
              <p>
                {{ event.date.date_gmt }}
              </p>
            </router-link>
          </div>
        </div>
      </div>
    </slick>
  </div>
</template>

<script>
import Slick from 'vue-slick'
import blog from '../api/blog'

export default {
  data() {
    return {
      events: [],
      slickOptions: {
        prevArrow: '',
        nextArrow: '<i class="fas fa-chevron-right"></i>',
      },
    }
  },
  components: { Slick },
  methods: {
    reInit() {
      this.$refs.slick.destroy()
      this.$nextTick(() => {
        this.$refs.slick.create()
      })
    },
  },
  mounted() {
    blog
      .getList({ tags: 1458 })
      .then(response => {
        this.events = response.data
        this.reInit()
      })
      .catch(error => {
        // handle error
        console.log(error)
      })
  },
}
</script>

<style lang="scss">
.event_slide {
  position: absolute;
  left: 0;
  bottom: 45px;
  width: 490px;
  max-width: 100%;
  padding: 10px;
  background-color: #000;
  color: #fff;
  @include media-breakpoint-down(lg) {
    position: static;
  }
  .event_slide_cont {
    a {
      color: #fff;
    }
    h2 {
      font-size: 1.111rem;
    }
    h3 {
      font-size: 0.888rem;
    }
    p {
      margin-bottom: 0;
    }
    .event_detail {
      padding-left: 10px;
      padding-right: 30px;
    }
  }

  .slick-arrow {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    font-size: 19px;
  }
}
</style>

<template>
  <div class="bottom_fix">
    <!-- <router-link
      class="my_btn"
      :to="{ name: 'article', params: { id: 67999 } }"
    >
      休業中
    </router-link> -->
    <a
      class="my_btn"
      href="#"
      @click.prevent="$store.commit('changeModal', true)"
    >
      予約する
      <i class="fas fa-chevron-right"></i>
    </a>
    <!-- <a class="my_btn" href="https://kurand.jp/" target="_blank"
      >お酒を購入する<i class="fas fa-chevron-right"></i
    ></a> -->
  </div>
</template>
<script>
export default {
  methods: {
    ctaClick() {
      //this.$store.commit('changeModal', true)
    },
  },
}
</script>

<template>
  <div class="container pb-5">
    <main id="main" class="site-main mw-100 w-75 mx-auto" role="main">
      <div class="reserved_page">
        <div class="eye_catch">
          <img src="../assets/reserved_catch.jpg" alt="" />
        </div>

        <h2 class="main_h">
          KURAND SAKE MARKETの貸切プランの魅力
        </h2>
        <div class="container point_area">
          <h3>
            日本酒・ビール・果実酒・焼酎・サワーが飲み比べし放題！
          </h3>
          <img src="../assets/reserved_1.jpg" alt="" />
          <p>
            全国各地の蔵元直送の日本酒約100種類に加え、生ビール、梅酒、果実酒、焼酎がございます。さらに貸切プランに限り、お酒の持ち込みも可能です。
            ※お水のご用意はありますが、ソフトドリンクはございませんのでご持参ください。
          </p>

          <h3>
            ウッド調のお洒落な内観
          </h3>
          <img src="../assets/reserved_2.jpg" alt="" />
          <p>
            グルメな友人とのフードパーティー、サークルの飲み会、会社の飲み会と、様々なコミュニティのパーティーに適した広さです。最低30名から、最大60名まで貸切可能です。
            ※新宿店のみ、45名以上からのご利用となります
          </p>

          <h3>
            マイク・プロジェクターの貸し出しOK！
          </h3>
          <img src="../assets/reserved_3.jpg" alt="" />
          <p>
            マイク・プロジェクターの貸し出しも行っているので、イベントの開催や会社の歓送迎会などにもぴったり！
          </p>

          <h3>
            持参した食材を、店内で自由に調理できます！
          </h3>
          <img src="../assets/reserved_4.jpg" alt="" />
          <p>
            店内では、カセットコンロ、鍋、包丁、まな板、フライパンなど、自由に料理を楽しむ道具も用意しています。醤油、コショウ、塩、お皿、お箸、スプーン、フォーク等は常時用意してあります。その他調味料はご持参ください。
          </p>

          <h3>
            出張サービスの利用OK！マグロの解体ショーやケータリングも！
          </h3>
          <img src="../assets/reserved_5.jpg" alt="" />
          <p>
            店内では食材の持ち込みが自由なだけでなく、マグロの解体ショーやケータリングなど、料理の出張サービスもご利用いただけます。
          </p>

          <h3>
            アクセスしやすい立地！
          </h3>
          <img src="../assets/reserved_6.jpg" alt="" />
          <p>
            アクセスが良い場所にあるので、集まりやすいのも特徴です。
          </p>

          <table class="table table-bordered mb-5">
            <tr>
              <th>貸切可能</th>
              <td>月、火、水、木、日、祝日</td>
            </tr>
            <tr>
              <th>貸切不可能</th>
              <td>金、土、祝前日、年末年始</td>
            </tr>
            <tr>
              <th>時間</th>
              <td>
                12時～16時、17時～23時（パーティー準備時間のご相談承ります。ご相談ください）
              </td>
            </tr>
            <tr>
              <th>最低保証金</th>
              <td>99,000円（30名様分）</td>
            </tr>
            <tr>
              <th>料金</th>
              <td>3,300円（税込）／1名</td>
            </tr>
            <tr>
              <th>最低ご利用可能人数</th>
              <td>
                30名（※店舗によって最低保証人数が変わります。詳しくはお問い合わせください。）新宿店のみ、45名以上からのご利用となります
              </td>
            </tr>
            <tr>
              <th>最大人数</th>
              <td>50～60名（店舗によります）</td>
            </tr>
            <tr>
              <th>ご使用いただけるもの</th>
              <td>
                調理器具（鍋、包丁、まな板、フライパン、カセットコンロ、お皿、お箸、スプーン、フォーク）、調味料（醤油、コショウ、塩）、マイク、プロジェクター（マイクとプロジェクターをご使用の場合は、事前にお申し付けください。）
              </td>
            </tr>
          </table>

          <div class="cancel_area">
            <h3>
              キャンセルの場合
            </h3>
            <p>
              ご利用日の1週間以上前のキャンセルは、キャンセル料は発生しません。ただし、ご利用日1週間を切ってのキャンセルは、最低保証金の50%返金致します。キャンセル確定後１週間ほどで該当口座へ返金致します。その際の払戻手数料はお客さまのご負担となります。
            </p>
          </div>
        </div>

        <div class="container store_area">
          <h2>
            店内の様子
          </h2>
          <div class="store_icos">
            <span v-for="(store, i) in storeList" :key="i">
              {{ store.title.rendered }}
            </span>
          </div>

          <div class="store_list">
            <template
              v-for="({ title, acf, better_featured_image }, i) in storeList"
            >
              <h3 :key="i">
                KURAND SAKE MARKET {{ title.rendered }}
                <small v-html="acf.address.replace('<br />', '')"></small>
              </h3>
              <div class="row store_cont" :key="'cont' + i">
                <div class="col-md-7">
                  <img :src="better_featured_image.source_url" alt="" />
                </div>
                <div class="col-md-5">
                  <p>ご予約専用番号：{{ acf.reserve_tel }}</p>

                  <a class="reserve_btn btn btn-block" :href="acf.airreserve">
                    {{ title.rendered }} 店で貸切予約する
                  </a>
                </div>
              </div>
            </template>
          </div>
        </div>

        <store-faq></store-faq>
      </div>
    </main>
    <!-- #main -->
  </div>
</template>
<script>
import StoreFaq from '../components/StoreFaq'
import { mapState } from 'vuex'
export default {
  components: {
    StoreFaq,
  },
  mounted() {
    scrollTo(0, 0)
  },
  watch: {
    $route() {
      scrollTo(0, 0)
    },
  },
  computed: {
    ...mapState(['storeList']),
  },
}
</script>
<style lang="scss" scoped>
.w-75 {
  @include sp {
    width: 100% !important;
  }
}
.reserved_page {
  .eye_catch {
    position: relative;
    margin-bottom: 30px;
    .tb {
      position: absolute;
      display: flex;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      justify-content: center;
      align-items: center;
      .tc {
        width: 100%;
        text-align: center;
        background-color: #ff4f749c;
        color: #fff;
        padding-top: 30px;
        padding-bottom: 70px;
        p {
          margin-bottom: 0;
          line-height: 110%;
        }
        .p1 {
          font-size: 30px;
        }
        .p2 {
          font-size: 90px;
        }
        .p3 {
          font-size: 23px;
        }
        .p4 {
          font-size: 28px;
          .big {
            font-size: 240%;
            line-height: 110%;
          }
        }
      }
    }
  }

  .main_h {
    background-color: #dcd2aa;
    text-align: center;
    padding: 10px 0;
    margin-bottom: 30px;
  }

  .point_area {
    margin-bottom: 40px;
    h3 {
      text-align: center;
      color: #ff4f74;
      margin-bottom: 20px;
    }
    img {
      display: block;
      margin: auto;
      margin-bottom: 20px;
    }
    p {
      margin-bottom: 40px;
    }
    .table {
      th {
        white-space: nowrap;
      }
    }

    .cancel_area {
      h3 {
        color: $gray-900;
      }
      p {
        margin-bottom: 0;
      }
      background-color: #ebebe6;
      padding: 30px;
    }
  }
  .store_area {
    h2 {
      text-align: center;
      border-top: 1px solid;
      border-bottom: 1px solid;
      padding: 10px 0;
      margin-bottom: 20px;
    }
    .store_icos {
      text-align: center;
      margin-bottom: 20px;
      span {
        display: inline-block;
        background-color: #f67079;
        color: #fff;
        border-radius: 5px;
        font-size: 18px;
        padding: 3px 20px;
      }
    }
    .store_list {
      h3 {
        text-align: center;
        margin-bottom: 20px;
        small {
          margin-top: 10px;
          display: block;
          font-size: 50%;
        }
      }
      .store_cont {
        margin-bottom: 40px;
      }

      p {
        margin-bottom: 0;
        line-height: 200%;
      }
      .reserve_btn {
        background-color: #f67079;
        color: #fff;
      }
    }
  }

  .slide_area {
    background-color: #ff4f74;
    padding: 20px 60px;
    position: relative;

    h3 {
      text-align: center;
      color: #fff;
      margin-bottom: 20px;
    }
    .reserve_slider {
      margin: 0 10px;
      img {
        padding: 0 10px;
      }
      .slick-arrow {
        position: absolute;
        color: #ff4f74;
        background-color: #fff;
        font-size: 40px;
        padding: 10px;
        top: 35%;
        &.arrow_left {
          left: -70px;
        }
        &.arrow_right {
          right: -70px;
        }
      }
    }
  }
}

.shop_map {
  height: 180px;
  width: 100%;
  border: #ccc solid 1px;
}
</style>

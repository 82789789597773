<template>
  <section>
    <div class="container">
      <div class="row banner_area text-center">
        <div class="col-lg-6 mb-5" v-for="(banner, i) in banners" :key="i">
          <a :href="banner.acf.url">
            <img :src="banner.better_featured_image.source_url" alt="" />
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import sakemarket from '../api/sakemarket'
export default {
  data() {
    return {
      banners: [],
    }
  },
  mounted() {
    sakemarket
      .getEcCampaigns()
      .then(response => {
        this.banners.push(...response.data)
      })
      .catch(error => {
        // handle error
        console.log(error)
      })
  },
}
</script>

import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from './views/Home.vue'
import Store from './views/Store.vue'
import Amazake from './views/Amazake.vue'
import Recruit from './views/Recruit.vue'
import Blog from './views/Blog.vue'
import Article from './views/Article.vue'
// import GroupDiscount from './views/GroupDiscount.vue'
import Reserved from './views/Reserved.vue'

import Cta from './components/Cta.vue'
import CtaStore from './components/CtaStore.vue'
import CtaRecruit from './components/CtaRecruit.vue'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  //base: '/sakemarket/',
  routes: [
    {
      path: '/',
      name: 'home',
      components: {
        default: Home,
        cta: Cta,
      },
    },
    {
      path: '/amazakelabo',
      name: 'amazake',
      component: Amazake,
    },
    {
      path: '/part-time-job',
      name: 'recruit',
      components: {
        default: Recruit,
        cta: CtaRecruit,
      },
    },
    // {
    //   path: '/group-discount',
    //   name: 'group-discount',
    //   component: GroupDiscount,
    // },
    {
      path: '/reserved',
      name: 'reserved',
      component: Reserved,
    },
    {
      path: '/blog',
      name: 'blog',
      components: {
        default: Blog,
      },
    },
    {
      path: '/blog/:id',
      name: 'article',
      components: {
        default: Article,
      },
    },
    {
      path: '/:slug',
      name: 'store',
      components: {
        default: Store,
        cta: CtaStore,
      },
    },
  ],
})

export default router
